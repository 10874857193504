<template>
    <!-- <Layout> -->
    <div class="px-1 position-relative">
        <div class="form-header">
            <h4>
                {{ viewMode ? $t('store view') : $t('store registration') }}
            </h4>

            <button
                class="btn btn-danger btn-lg fw-bold"
                @click.prevent="submitForm">
                {{ $t('submit') }}
            </button>
        </div>
        <div class="card overflow-hidden">
            <div class="card-body">
                <LoadingTableForm
                    :tables="3"
                    :cols="4"
                    :rows="10"
                    class="table-bordered bg-white mb-0"
                    v-if="loading" />
                <b-form v-else>
                    <table class="table table-bordered table-nowrap table-form">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="4">
                                    <div class="d-flex justify-content-between">
                                        {{ $t('account') }}
                                        <div class="form-check-success">
                                            <b-form-checkbox
                                                v-model="pl.status"
                                                switch
                                                value="active"
                                                unchecked-value="inactive">
                                                {{ $t(pl.status) }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('type') }}
                                </th>
                                <td style="width: 35%">
                                    <div
                                        class="d-flex gap-2 mt-2"
                                        v-if="!viewMode">
                                        <b-form-radio
                                            :disabled="pl.type == referrer_type"
                                            name="type"
                                            value="offline"
                                            v-model="pl.type">
                                            {{ $t('offline') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            :disabled="pl.type == referrer_type"
                                            name="type"
                                            value="online"
                                            v-model="pl.type">
                                            {{ $t('online') }}
                                        </b-form-radio>
                                    </div>
                                    <div
                                        v-else
                                        class="d-flex align-items-center gap-1 mt-2">
                                        <i
                                            :class="`mdi ${
                                                pl.type == 'online'
                                                    ? 'mdi-web text-info'
                                                    : pl.type == 'offline'
                                                    ? 'mdi-storefront text-success'
                                                    : ''
                                            } fs-5`"></i>
                                        <span>{{ $t(pl.type) }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="viewMode">
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('agents') }}
                                </th>
                                <td colspan="3">
                                    <div class="d-flex gap-4 mt-2">
                                        <div
                                            v-for="r in pl.agent_upline"
                                            :key="r._id"
                                            class="td-username justify-content-center">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-secondary"
                                                @click="
                                                    goToTicketForm(
                                                        r.username,
                                                        'agent'
                                                    )
                                                ">
                                                <i
                                                    class="bx bx-envelope bx-tada-hover fs-4"></i
                                            ></a>

                                            <a
                                                href="javascript:void(0)"
                                                @click="
                                                    goToUserForm(r._id, 'agent')
                                                "
                                                class="link-username"
                                                ><i
                                                    :class="`mdi mdi-numeric-${r.agent.level}-box fs-5  level-${r.agent.level}`"></i>
                                                {{ r.username }}</a
                                            >
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    <i
                                        :class="`mdi ${
                                            referrer_type == 'online'
                                                ? 'mdi-web text-info'
                                                : referrer_type == 'offline'
                                                ? 'mdi-storefront text-success'
                                                : ''
                                        } fs-5`"></i>
                                    <i
                                        :class="`mdi ${
                                            referrer_role == 'agent'
                                                ? 'mdi-account-tie text-danger'
                                                : referrer_role == 'player'
                                                ? 'mdi-account-cowboy-hat text-primary'
                                                : ''
                                        } fs-5`"></i
                                    >{{ $t('high-level store') }}
                                </th>
                                <td>
                                    <div v-if="!viewMode">
                                        <input
                                            :readonly="viewMode"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    v$.pl.agent_id.$error,
                                                'is-valid':
                                                    !viewMode &&
                                                    pl.agent_id &&
                                                    !v$.pl.agent_id.$error,
                                            }"
                                            :placeholder="`${$t(
                                                'enter referrer code or username'
                                            )}`"
                                            v-model="referrer_code"
                                            @input="getReferrer()" />
                                        <div
                                            v-if="
                                                v$.pl.agent_id.validReferrer
                                                    .$invalid
                                            "
                                            class="invalid-feedback">
                                            {{
                                                $t(
                                                    v$.pl.agent_id.validReferrer
                                                        .$message
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            class="d-flex align-items-center mt-2"
                                            v-if="pl.agent_id">
                                            <i
                                                class="mdi mdi-account-tie text-danger fs-5"></i>
                                            <i
                                                :class="`mdi mdi-numeric-${pl.agent_referrer.agent.level}-box fs-5  level-${pl.agent_referrer.agent.level}`"></i>
                                            <span class="mb-0 lh-1">
                                                {{ pl.agent_referrer.username }}
                                            </span>
                                        </div>
                                        <div v-else class="mt-2">
                                            <span class="mb-0 lh-1 text-danger">
                                                {{ $t('no referrer') }}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" v-if="viewMode">
                                    {{ $t('level') }}
                                </th>
                                <td v-if="viewMode">
                                    <i
                                        :class="`mdi mdi-numeric-${pl.agent.level}-box level-${pl.agent.level} fs-3 mt-2`"></i>
                                    <!-- <multiselect
                                                :placeholder="`${$t('select level')}`"
                                                v-model="pl.agent.level"
                                                :options="level_options"
                                                label="name"
                                                :can-deselect="false"
                                                :can-clear="false"
                                                :class="{'is-invalid':submitted && v$.pl.agent.level.$error}"
                                            >
                                                <template v-slot:singlelabel="{value}">
                                                    <span class="flex-fill ps-2">{{ $t('level')+' '+value.value }}</span>
                                                </template>
                                                <template v-slot:option="{ option  }">
                                                    {{$t('level') +' '+ option.value}}
                                                </template>
                                            </multiselect>
                                            <div
                                                v-for="(item, index) in v$.pl.agent.level.$errors"
                                                :key="index"
                                                class="invalid-feedback"
                                            >
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div> -->
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('username') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        autocomplete="off"
                                        :readonly="viewMode"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': v$.pl.username.$error,
                                            'is-valid':
                                                !viewMode &&
                                                pl.username &&
                                                !v$.pl.username.$error,
                                        }"
                                        :placeholder="`${$t('username')}`"
                                        v-model="pl.username"
                                        @input="getUsernames()" />

                                    <div
                                        v-if="
                                            v$.pl.username.available.$invalid &&
                                            !v$.pl.username.required.$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.username.available
                                                    .$message
                                            )
                                        }}
                                    </div>
                                    <div
                                        v-if="v$.pl.username.required.$invalid"
                                        class="invalid-feedback">
                                        {{
                                            $t(v$.pl.username.required.$message)
                                        }}
                                    </div>
                                    <div
                                        v-if="v$.pl.username.minLength.$invalid"
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.username.minLength
                                                    .$message
                                            )
                                        }}
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('store name') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`name`)"
                                        v-model="pl.name"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.pl.name.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.name
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('password') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-1 align-items-start">
                                        <div class="flex-fill">
                                            <input
                                                autocomplete="new-password"
                                                :type="
                                                    show_password
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                class="form-control"
                                                :placeholder="$t(`password`)"
                                                v-model="pl.password"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.password.$error,
                                                }" />

                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .password.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex-fill">
                                            <input
                                                type="password"
                                                class="form-control"
                                                :placeholder="
                                                    $t(`confirm password`)
                                                "
                                                v-model="pl.confirm_password"
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.confirm_password
                                                            .$error,
                                                }" />
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .confirm_password.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <button
                                            v-if="viewMode"
                                            class="btn btn-outline-light"
                                            @click.prevent="
                                                show_password = !show_password
                                            ">
                                            <i
                                                :class="`mdi ${
                                                    show_password
                                                        ? 'mdi-eye-off-outline'
                                                        : 'mdi-eye-outline'
                                                }`"></i>
                                        </button>
                                        <button
                                            v-if="viewMode"
                                            class="btn btn-outline-info"
                                            @click.prevent="getPassword()">
                                            {{ $t('show') }}
                                        </button>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('charging/exchange password') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-1 align-items-start">
                                        <div class="flex-fill">
                                            <input
                                                :type="
                                                    show_pin
                                                        ? 'text'
                                                        : 'password'
                                                "
                                                class="form-control"
                                                :placeholder="
                                                    $t(
                                                        'charging/exchange password'
                                                    )
                                                "
                                                maxlength="4"
                                                v-model="pl.pin"
                                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13; "
                                                :class="{
                                                    'is-invalid':
                                                        submitted &&
                                                        v$.pl.pin.$error,
                                                }" />
                                            <div
                                                v-for="(item, index) in v$.pl
                                                    .pin.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <button
                                            v-if="viewMode"
                                            class="btn btn-outline-light"
                                            @click.prevent="
                                                show_pin = !show_pin
                                            ">
                                            <i
                                                :class="`mdi ${
                                                    show_pin
                                                        ? 'mdi-eye-off-outline'
                                                        : 'mdi-eye-outline'
                                                }`"></i></button
                                        ><button
                                            v-if="viewMode"
                                            class="btn btn-outline-info"
                                            @click.prevent="getPin()">
                                            {{ $t('show') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('email address') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`email address`)"
                                        v-model="pl.email"
                                        :class="{
                                            'is-invalid':
                                                submitted && v$.pl.email.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.email
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('mobile number') }}
                                </th>
                                <td style="width: 35%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`mobile number`)"
                                        v-model="pl.mobile_number"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.mobile_number.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl
                                            .mobile_number.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('create agent') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="create agent"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities.create_agent
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="create agent"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities.create_agent
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('create player') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="create player"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities.create_player
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="create player"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities.create_player
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('add agent balance') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="add agent balance"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities
                                                    .add_agent_balance
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="add agent balance"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities
                                                    .add_agent_balance
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('add player balance') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="add player balance"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities
                                                    .add_player_balance
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="add player balance"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities
                                                    .add_player_balance
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('subtract agent balance') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="subtract agent balance"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities
                                                    .subtract_agent_balance
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="subtract agent balance"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities
                                                    .subtract_agent_balance
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('subtract player balance') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="subtract player balance"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities
                                                    .subtract_player_balance
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="subtract player balance"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities
                                                    .subtract_player_balance
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('update player password') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="update player password"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities
                                                    .update_player_password
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="update player password"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities
                                                    .update_player_password
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('view player password') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-2 mt-2">
                                        <b-form-radio
                                            name="view player password"
                                            :value="true"
                                            v-model="
                                                pl.agent.abilities
                                                    .view_player_password
                                            ">
                                            {{ $t('possible') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="view player password"
                                            :value="false"
                                            v-model="
                                                pl.agent.abilities
                                                    .view_player_password
                                            ">
                                            {{ $t('impossible') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="viewMode">
                                <th class="bg-light bg-soft">
                                    {{
                                        $t('Membership money increase/decrease')
                                    }}
                                </th>
                                <td colspan="3">
                                    <div class="d-flex align-items-start gap-2">
                                        <div>
                                            <VueNumberFormat
                                                :disabled="pl_asset.loading"
                                                class="form-control text-end rounded-end"
                                                :class="{
                                                    'is-invalid':
                                                        asset_submitted &&
                                                        v$.pl_asset.amount
                                                            .$error,
                                                }"
                                                v-model:value="pl_asset.amount"
                                                :options="{
                                                    precision: 0,
                                                    prefix: '',
                                                    suffix: '',
                                                    thousand: ',',
                                                    acceptNegative: false,
                                                    isInteger: true,
                                                }"></VueNumberFormat>
                                            <div
                                                v-for="(item, index) in v$
                                                    .pl_asset.amount.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="flex-fill">
                                            <input
                                                :disabled="pl_asset.loading"
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                    $t(`enter your note`)
                                                "
                                                v-model="pl_asset.note"
                                                :class="{
                                                    'is-invalid':
                                                        asset_submitted &&
                                                        v$.pl_asset.note.$error,
                                                }" />
                                            <div
                                                v-for="(item, index) in v$
                                                    .pl_asset.note.$errors"
                                                :key="index"
                                                class="invalid-feedback">
                                                <span v-if="item.$message">
                                                    {{ $t(item.$message) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <b-spinner
                                                type="grow"
                                                variant="dark"
                                                role="status"
                                                medium
                                                v-if="
                                                    pl_asset.loading
                                                "></b-spinner>
                                            <div class="button-items" v-else>
                                                <button
                                                    class="btn btn-outline-success"
                                                    @click.prevent="
                                                        submitAsset(
                                                            'balance',
                                                            'add'
                                                        )
                                                    ">
                                                    {{ $t('add') }}
                                                </button>
                                                <button
                                                    class="btn btn-outline-danger"
                                                    @click.prevent="
                                                        submitAsset(
                                                            'balance',
                                                            'subtract'
                                                        )
                                                    ">
                                                    {{ $t('subtract') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('referral code') }}
                                </th>
                                <td>
                                    <input
                                        :readonly="viewMode"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                v$.pl.agent.referral_code
                                                    .$error,
                                            'is-valid':
                                                !viewMode &&
                                                pl.agent.referral_code &&
                                                !v$.pl.agent.referral_code
                                                    .$error,
                                        }"
                                        :placeholder="`${$t('referral code')}`"
                                        v-model="pl.agent.referral_code"
                                        @input="checkReferrerCode()" />
                                    <div
                                        v-if="
                                            v$.pl.agent.referral_code.available
                                                .$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.agent.referral_code
                                                    .available.$message
                                            )
                                        }}
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                            v$.pl.agent.referral_code.minLength
                                                .$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.agent.referral_code
                                                    .minLength.$message
                                            )
                                        }}
                                    </div>
                                    <div
                                        v-if="
                                            submitted &&
                                            v$.pl.agent.referral_code.requiredIf
                                                .$invalid
                                        "
                                        class="invalid-feedback">
                                        {{
                                            $t(
                                                v$.pl.agent.referral_code
                                                    .requiredIf.$message
                                            )
                                        }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('note') }}
                                </th>
                                <td colspan="3">
                                    <textarea
                                        rows="5"
                                        class="form-control"
                                        style="resize: none"
                                        :placeholder="`${$t(
                                            'enter note here'
                                        )}`"
                                        v-model="pl.agent.note"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        v-if="['all', 'online'].includes(player_mode)"
                        class="table table-bordered table-nowrap table-form handler-table">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="4">
                                    {{ $t('handler bank') }}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td class="p-0">
                                    <table class="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-start"
                                                    colspan="2"
                                                    :class="`${
                                                        pl.handler_bank.physical
                                                            .default
                                                            ? 'table-primary'
                                                            : ''
                                                    }`">
                                                    <div
                                                        class="d-flex justify-content-between">
                                                        <b-form-radio
                                                            name="default-handler-bank"
                                                            v-model="
                                                                pl.handler_bank
                                                                    .physical
                                                                    .default
                                                            ">
                                                            <span
                                                                class="fw-bold">
                                                                {{
                                                                    $t(
                                                                        'physical'
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    pl
                                                                        .handler_bank
                                                                        .physical
                                                                        .default
                                                                "
                                                                class="text-muted ms-1"
                                                                >({{
                                                                    $t(
                                                                        'default'
                                                                    )
                                                                }})</span
                                                            >
                                                        </b-form-radio>
                                                        <b-form-checkbox
                                                            switch
                                                            v-model="
                                                                pl.handler_bank
                                                                    .physical
                                                                    .enabled
                                                            ">
                                                            {{
                                                                pl.handler_bank
                                                                    .physical
                                                                    .enabled
                                                                    ? $t(
                                                                          'enabled'
                                                                      )
                                                                    : $t(
                                                                          'disabled'
                                                                      )
                                                            }}
                                                        </b-form-checkbox>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t('bank name') }}
                                                </th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`bank name`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .physical
                                                                .bank_name
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .physical
                                                                    .bank_name
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .physical.bank_name
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account holder') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account holder`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .physical
                                                                .account_holder
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .physical
                                                                    .account_holder
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .physical
                                                            .account_holder
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account number') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account number`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .physical
                                                                .account_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .physical
                                                                    .account_number
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .physical
                                                            .account_number
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td class="p-0">
                                    <table class="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    class="text-start"
                                                    colspan="2"
                                                    :class="`${
                                                        pl.handler_bank.virtual
                                                            .default
                                                            ? 'table-primary'
                                                            : ''
                                                    }`">
                                                    <div
                                                        class="d-flex justify-content-between">
                                                        <b-form-radio
                                                            name="default-handler-bank"
                                                            v-model="
                                                                pl.handler_bank
                                                                    .virtual
                                                                    .default
                                                            ">
                                                            <span
                                                                class="fw-bold">
                                                                {{
                                                                    $t(
                                                                        'virtual'
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    pl
                                                                        .handler_bank
                                                                        .virtual
                                                                        .default
                                                                "
                                                                class="text-muted ms-1"
                                                                >({{
                                                                    $t(
                                                                        'default'
                                                                    )
                                                                }})</span
                                                            >
                                                        </b-form-radio>
                                                        <b-form-checkbox
                                                            switch
                                                            v-model="
                                                                pl.handler_bank
                                                                    .virtual
                                                                    .enabled
                                                            ">
                                                            {{
                                                                pl.handler_bank
                                                                    .virtual
                                                                    .enabled
                                                                    ? $t(
                                                                          'enabled'
                                                                      )
                                                                    : $t(
                                                                          'disabled'
                                                                      )
                                                            }}
                                                        </b-form-checkbox>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th class="bg-light bg-soft">
                                                    {{ $t('bank name') }}
                                                </th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`bank name`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .virtual
                                                                .bank_name
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .virtual
                                                                    .bank_name
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .virtual.bank_name
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account holder') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account holder`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .virtual
                                                                .account_holder
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .virtual
                                                                    .account_holder
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .virtual
                                                            .account_holder
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th
                                                    class="bg-light bg-soft"
                                                    style="width: 25%">
                                                    {{ $t('account number') }}
                                                </th>
                                                <td style="width: 25%">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="
                                                            $t(`account number`)
                                                        "
                                                        v-model="
                                                            pl.handler_bank
                                                                .virtual
                                                                .account_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.pl
                                                                    .handler_bank
                                                                    .virtual
                                                                    .account_number
                                                                    .$error,
                                                        }" />
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.pl.handler_bank
                                                            .virtual
                                                            .account_number
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="
                                                                item.$message
                                                            ">
                                                            {{
                                                                $t(
                                                                    item.$message
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered table-nowrap table-form">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="4">
                                    <div
                                        class="d-flex justify-content-start gap-3">
                                        {{ $t('commission') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.agent.commissions.enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('commission type') }}
                                </th>
                                <td style="width: 35%">
                                    <div class="d-flex gap-5 mt-2">
                                        <b-form-radio
                                            name="commission type"
                                            value="bet"
                                            v-model="pl.agent.commissions.type">
                                            {{ $t('bet') }}
                                        </b-form-radio>
                                        <b-form-radio
                                            name="commission type"
                                            value="losing"
                                            v-model="pl.agent.commissions.type">
                                            {{ $t('losing') }}
                                        </b-form-radio>
                                    </div>
                                </td>
                                <th
                                    class="bg-light bg-soft"
                                    style="width: 15%"
                                    v-if="
                                        pl.agent.commissions.type == 'losing'
                                    ">
                                    <div class="d-flex justify-content-between">
                                        {{ $t('losing') }}
                                        <b-form-checkbox
                                            switch
                                            v-model="
                                                pl.agent.commissions.losing
                                                    .enabled
                                            ">
                                        </b-form-checkbox>
                                    </div>
                                </th>
                                <td
                                    v-if="
                                        pl.agent.commissions.type == 'losing'
                                    ">
                                    <VueNumberFormat
                                        :disabled="
                                            !pl.agent.commissions.losing.enabled
                                        "
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.commissions.losing
                                                    .value.$error,
                                        }"
                                        v-model:value="
                                            pl.agent.commissions.losing.value
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .commissions.losing.value.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        class="table table-bordered table-nowrap table-form"
                        v-if="client_name == 'hera'">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="10">
                                    {{ $t('payment info') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 10%">
                                    {{ $t('agent deposit') }}
                                </th>
                                <td style="width: 10%">
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.payments
                                                    .total_deposit.$error,
                                        }"
                                        v-model:value="
                                            pl.agent.payments.total_deposit
                                        "
                                        :options="{
                                            precision: 0,
                                            prefix: '',
                                            suffix: '',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: true,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .payments.total_deposit.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 10%">
                                    {{ $t('hera commission') }}
                                </th>
                                <td style="width: 10%">
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.payments
                                                    .website_commission.$error,
                                        }"
                                        v-model:value="
                                            pl.agent.payments.website_commission
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .payments.website_commission
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 10%">
                                    {{ $t('bank commission') }}
                                </th>
                                <td style="width: 10%">
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.payments
                                                    .bank_commission.$error,
                                        }"
                                        v-model:value="
                                            pl.agent.payments.bank_commission
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .payments.bank_commission.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 10%">
                                    {{ $t('provider charges') }}
                                </th>
                                <td style="width: 10%">
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.payments
                                                    .provider_commission.$error,
                                        }"
                                        v-model:value="
                                            pl.agent.payments
                                                .provider_commission
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '%',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .payments.provider_commission
                                            .$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 10%">
                                    {{ $t('total balance') }}
                                </th>
                                <td style="width: 10%">
                                    <VueNumberFormat
                                        :disabled="true"
                                        class="form-control text-end rounded-end"
                                        v-model:value="
                                            pl.agent.payments.total_balance
                                        "
                                        :options="{
                                            precision: 2,
                                            prefix: '',
                                            suffix: '',
                                            decimal: '.',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: false,
                                        }"></VueNumberFormat>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <b-collapse :visible="pl.agent.commissions.type == 'bet'">
                        <div class="row">
                            <div class="col-md-4">
                                <table
                                    class="table table-bordered table-nowrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                <div
                                                    class="d-flex justify-content-start gap-3">
                                                    {{ $t('powerball') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                {{ $t('type') }}
                                            </th>
                                            <td style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    <b-form-radio
                                                        name="some-radios1"
                                                        value="single"
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball.type
                                                        ">
                                                        {{ $t('single') }}
                                                    </b-form-radio>
                                                    <b-form-radio
                                                        name="some-radios1"
                                                        value="multiple"
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball.type
                                                        ">
                                                        {{ $t('multiple') }}
                                                    </b-form-radio>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.agent.commissions.minigames
                                                    .powerball.type == 'single'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between justify-content-between">
                                                    {{ $t('single') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball
                                                                .single.enabled
                                                        "></b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.minigames.powerball.single.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.minigames.powerball.single.value.$error,}"
                                                        v-model="pl.agent.commissions.minigames.powerball.single.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .minigames.powerball.single.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .minigames.powerball
                                                            .single.enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .single.value
                                                                .$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .minigames.powerball
                                                            .single.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .minigames.powerball
                                                        .single.value.$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.agent.commissions.minigames
                                                    .powerball.type ==
                                                'multiple'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('multiple-single') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.single
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.minigames.powerball.multiple.single.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.minigames.powerball.multiple.single.value.$error,}"
                                                        v-model="pl.agent.commissions.minigames.powerball.multiple.single.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .minigames.powerball.multiple.single.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .minigames.powerball
                                                            .multiple.single
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.single
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .minigames.powerball
                                                            .multiple.single
                                                            .value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .minigames.powerball
                                                        .multiple.single.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.agent.commissions.minigames
                                                    .powerball.type ===
                                                'multiple'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('multiple-double') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.double
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.minigames.powerball.multiple.double.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.minigames.powerball.multiple.double.value.$error,}"
                                                        v-model="pl.agent.commissions.minigames.powerball.multiple.double.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .minigames.powerball.multiple.double.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .minigames.powerball
                                                            .multiple.double
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.double
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .minigames.powerball
                                                            .multiple.double
                                                            .value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .minigames.powerball
                                                        .multiple.double.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="
                                                pl.agent.commissions.minigames
                                                    .powerball.type ===
                                                'multiple'
                                            ">
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('multiple-triple') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.triple
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.minigames.powerball.multiple.triple.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.minigames.powerball.multiple.triple.value.$error,}"
                                                        v-model="pl.agent.commissions.minigames.powerball.multiple.triple.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .minigames.powerball.multiple.triple.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .minigames.powerball
                                                            .multiple.triple
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .minigames
                                                                .powerball
                                                                .multiple.triple
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .minigames.powerball
                                                            .multiple.triple
                                                            .value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .minigames.powerball
                                                        .multiple.triple.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-4">
                                <table
                                    class="table table-bordered table-nowrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                {{ $t('casino') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('table') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .casino.table
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.casino.table.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.casino.table.value.$error,}"
                                                        v-model="pl.agent.commissions.casino.table.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .casino.table.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('slots') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .casino.slots
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.casino.slots.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.casino.slots.value.$error,}"
                                                        v-model="pl.agent.commissions.casino.slots.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .casino.slots.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .casino.slots
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .casino.slots
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .casino.slots.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .casino.slots.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                         
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('losing') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .casino.losing
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="form-group form-percent">
                                                    <input  
                                                        type="text" 
                                                        class="form-control text-end" 
                                                        onkeypress="if(!/[\d.]/.test(event.key) || (event.key === '.' && this.value.includes('.'))) event.preventDefault()"
                                                        oninput="if(this.value.startsWith('.')) this.value = '0' + this.value; 
                                                                if(this.value.includes('.') && this.value.split('.')[1].length > 2) this.value = this.value.slice(0, this.value.indexOf('.') + 3)"
                                                        :disabled="!pl.agent.commissions.casino.losing.enabled"
                                                        :class="{'is-invalid':submitted &&v$.pl.agent.commissions.casino.losing.value.$error,}"
                                                        v-model="pl.agent.commissions.casino.losing.value"
                                                        >
                                                    <div
                                                        v-for="(item, index) in v$
                                                            .pl.agent.commissions
                                                            .casino.losing.value
                                                            .$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span v-if="item.$message">
                                                            {{ $t(item.$message) }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .casino.losing
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .casino.losing
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .casino.losing.value
                                                    "
                                                    :options="{
                                                        precision: 2,
                                                        prefix: '',
                                                        suffix: '%',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: false,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .casino.losing.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div> -->
                                            </td>
                                        </tr>
                                         <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                <div
                                                    class="d-flex justify-content-start gap-2">
                                                    {{ $t('baccarat') }} - <span class="text-primary" >{{$t('mamahot')}}</span> 
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="pl.agent.commissions.casino.baccarat.enabled">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('type') }}
                                                   
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <multiselect
                                                    v-model="pl.agent.commissions.casino.baccarat.type"
                                                    :options="['all', 'win', 'lose','none']"
                                                    label="name"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                >
                                                    <template v-slot:singlelabel="{value}">
                                                        {{ $t(value.value) }}
                                                    </template>
                                                    <template v-slot:option="{ option  }">
                                                         {{ $t(option.value) }}
                                                    </template>
                                                </multiselect>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex">
                                                    {{ $t('commission on both betting') }}&nbsp;<span class="text-uppercase text-success">({{ $t('any amount') }})</span>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <div class="d-flex">
                                                    <b-form-checkbox class="mt-2 flex-fill" 
                                                        switch
                                                        :disabled="['win','lose', 'none'].includes(pl.agent.commissions.casino.baccarat.type) || 
                                                        (pl.agent.commissions.casino.baccarat.parallel_any_amount&&['all'].includes(pl.agent.commissions.casino.baccarat.type))"
                                                        v-model="pl.agent.commissions.casino.baccarat.parallel_any_amount">
                                                    </b-form-checkbox>
                                                     <multiselect
                                                        v-if="pl.agent.commissions.casino.baccarat.parallel_any_amount"
                                                        v-model="pl.agent.commissions.casino.baccarat.parallel_any_amount_type"
                                                        :options="[ 'win', 'lose']"
                                                        label="name"
                                                        :can-deselect="false"
                                                        :can-clear="false"
                                                    >
                                                        <template v-slot:singlelabel="{value}">
                                                            {{ $t(value.value) }}
                                                        </template>
                                                        <template v-slot:option="{ option  }">
                                                            {{ $t(option.value) }}
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex">
                                                    {{ $t('commission on both betting') }}&nbsp;<span class="text-uppercase text-success">({{ $t('same amount') }})</span>
                                                   
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <b-form-checkbox class="mt-2"
                                                    switch
                                                        :disabled="['win','lose', 'none'].includes(pl.agent.commissions.casino.baccarat.type) || 
                                                        (pl.agent.commissions.casino.baccarat.parallel_same_amount&&['all'].includes(pl.agent.commissions.casino.baccarat.type))"
                                                    v-model="pl.agent.commissions.casino.baccarat.parallel_same_amount">
                                                </b-form-checkbox></td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex">
                                                    {{ $t('commission on higher bet') }}
                                                   
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <b-form-checkbox class="mt-2"
                                                    switch
                                                     :disabled="['win','lose', 'none'].includes(pl.agent.commissions.casino.baccarat.type) || 
                                                        (pl.agent.commissions.casino.baccarat.parallel_higher_amount&&['all'].includes(pl.agent.commissions.casino.baccarat.type))"
                                                    v-model="pl.agent.commissions.casino.baccarat.parallel_higher_amount">
                                                </b-form-checkbox></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-4">
                                <table
                                    class="table table-bordered table-nowrap table-form">
                                    <thead>
                                        <tr class="fs-5 bg-warning bg-soft">
                                            <th class="text-start" colspan="6">
                                                {{ $t('safety') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('table') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .safety.table
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .safety.table
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .safety.table
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .safety.table.value
                                                    "
                                                    :options="{
                                                        precision: 0,
                                                        prefix: '',
                                                        suffix: '',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .safety.table.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                class="bg-light bg-soft"
                                                style="width: 50%">
                                                <div
                                                    class="d-flex justify-content-between">
                                                    {{ $t('slots') }}
                                                    <b-form-checkbox
                                                        switch
                                                        v-model="
                                                            pl.agent.commissions
                                                                .safety.slots
                                                                .enabled
                                                        ">
                                                    </b-form-checkbox>
                                                </div>
                                            </th>
                                            <td style="width: 50%">
                                                <VueNumberFormat
                                                    :disabled="
                                                        !pl.agent.commissions
                                                            .safety.slots
                                                            .enabled
                                                    "
                                                    class="form-control text-end rounded-end"
                                                    :class="{
                                                        'is-invalid':
                                                            submitted &&
                                                            v$.pl.agent
                                                                .commissions
                                                                .safety.slots
                                                                .value.$error,
                                                    }"
                                                    v-model:value="
                                                        pl.agent.commissions
                                                            .safety.slots.value
                                                    "
                                                    :options="{
                                                        precision: 0,
                                                        prefix: '',
                                                        suffix: '',
                                                        decimal: '.',
                                                        thousand: ',',
                                                        acceptNegative: false,
                                                        isInteger: true,
                                                    }"></VueNumberFormat>
                                                <div
                                                    v-for="(item, index) in v$
                                                        .pl.agent.commissions
                                                        .safety.slots.value
                                                        .$errors"
                                                    :key="index"
                                                    class="invalid-feedback">
                                                    <span v-if="item.$message">
                                                        {{ $t(item.$message) }}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-collapse>
                    <table class="table table-bordered table-wrap table-form">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="4">
                                    {{ $t('bet limit') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('table minimum bet amount') }}
                                </th>
                                <td>
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.table.minimum_amount
                                                    .$error,
                                        }"
                                        v-model:value="
                                            pl.agent.table.minimum_amount
                                        "
                                        :options="{
                                            precision: 0,
                                            prefix: '',
                                            suffix: '',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: true,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .table.minimum_amount.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>

                                <th class="bg-light bg-soft">
                                    {{ $t('table maximum bet amount') }}
                                </th>
                                <td>
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.table.maximum_amount
                                                    .$error,
                                        }"
                                        v-model:value="
                                            pl.agent.table.maximum_amount
                                        "
                                        :options="{
                                            precision: 0,
                                            prefix: '',
                                            suffix: '',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: true,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .table.maximum_amount.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft">
                                    {{ $t('slots minimum bet amount') }}
                                </th>
                                <td>
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.slots.minimum_amount
                                                    .$error,
                                        }"
                                        v-model:value="
                                            pl.agent.slots.minimum_amount
                                        "
                                        :options="{
                                            precision: 0,
                                            prefix: '',
                                            suffix: '',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: true,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .slots.minimum_amount.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>

                                <th class="bg-light bg-soft">
                                    {{ $t('slots maximum bet amount') }}
                                </th>
                                <td>
                                    <VueNumberFormat
                                        class="form-control text-end rounded-end"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.agent.slots.maximum_amount
                                                    .$error,
                                        }"
                                        v-model:value="
                                            pl.agent.slots.maximum_amount
                                        "
                                        :options="{
                                            precision: 0,
                                            prefix: '',
                                            suffix: '',
                                            thousand: ',',
                                            acceptNegative: false,
                                            isInteger: true,
                                        }"></VueNumberFormat>
                                    <div
                                        v-for="(item, index) in v$.pl.agent
                                            .slots.maximum_amount.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered table-nowrap table-form">
                        <thead>
                            <tr class="fs-5 bg-light bg-soft">
                                <th class="text-start" colspan="6">
                                    {{ $t('messenger') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('telegram') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`telegram`)"
                                        v-model="pl.messenger.telegram"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.telegram.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .telegram.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('line') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`line`)"
                                        v-model="pl.messenger.line"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.line.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .line.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('whatsapp') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`whatsapp`)"
                                        v-model="pl.messenger.whatsapp"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.whatsapp.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .whatsapp.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('wechat') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`wechat`)"
                                        v-model="pl.messenger.wechat"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.wechat.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .wechat.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('facebook') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`facebook`)"
                                        v-model="pl.messenger.facebook"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.facebook.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .facebook.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('skype') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`skype`)"
                                        v-model="pl.messenger.skype"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.skype.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .skype.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('viber') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`viber`)"
                                        v-model="pl.messenger.viber"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.viber.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .viber.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('qq') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`qq`)"
                                        v-model="pl.messenger.qq"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.qq.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .qq.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('kakao') }}
                                </th>
                                <td style="width: 15%">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`kakao`)"
                                        v-model="pl.messenger.kakao"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v$.pl.messenger.kakao.$error,
                                        }" />
                                    <div
                                        v-for="(item, index) in v$.pl.messenger
                                            .kakao.$errors"
                                        :key="index"
                                        class="invalid-feedback">
                                        <span v-if="item.$message">
                                            {{ $t(item.$message) }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import Pagination from '../../../components/pagination.vue';
import Swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect';
import LoadingTableForm from '@/components/loading-table-form';
import {
    required,
    requiredIf,
    minLength,
    maxLength,
    minValue,
    maxValue,
    sameAs,
    email,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import ui from '../../../mixins/ui';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import convert from '../../../mixins/convert';
import string from '../../../mixins/string';

// custom utilities
const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const validPassword = (value) => {
    const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]+$/;
    return !params.id ? helpers.req(value) && regex.test(value) : true;
};
const alphaNumericOnly = (value) => {
    // const regex = /^(?:[a-zA-Z0-9][a-zA-Z0-9_]*[a-zA-Z0-9]|)$/;
    const regex = /^[A-Za-z0-9]+$/;
    return !params.id ? helpers.req(value) && regex.test(value) : true;
};

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Multiselect,
        LoadingTableForm,
    },
    mixins: [datetime, number, convert, string, ui],
    data() {
        return {
            title: 'Agent Form',
            submitted: false,
            asset_submitted: false,
            _id: this.$route.query.id,
            // REAL DATAd
            pl: {
                agent_id: null,
                type: 'offline',
                username: null,
                status: 'active',
                password: null,
                confirm_password: null,
                pin: null,
                name: null,
                email: null,
                mobile_number: null,
                messenger: {
                    telegram: null,
                    line: null,
                    whatsapp: null,
                    wechat: null,
                    facebook: null,
                    skype: null,
                    viber: null,
                    qq: null,
                    kakao: null,
                },
                agent_referrer: {
                    agent: { level: null },
                    role: null,
                    name: null,
                    username: null,
                },
                agent_upline: [],
                customer_bank: {
                    physical: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    virtual: {
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                },
                handler_bank: {
                    physical: {
                        enabled: false,
                        default: true,
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                    virtual: {
                        enabled: false,
                        default: false,
                        bank_name: null,
                        account_holder: null,
                        account_number: null,
                    },
                },
                agent: {
                    level: null,
                    note: null,
                    referral_code: null,
                    abilities: {
                        create_agent: false,
                        add_agent_balance: false,
                        subtract_agent_balance: false,
                        create_player: false,
                        add_player_balance: false,
                        subtract_player_balance: false,
                        update_player_password: false,
                        view_player_password: false,
                    },
                    commissions: {
                        type: 'bet',
                        enabled: true,
                        losing: {
                            value: 0,
                            enabled: false,
                        },
                        minigames: {
                            powerball: {
                                type: 'single',
                                enabled: false,
                                single: {
                                    value: 0,
                                    enabled: false,
                                },
                                multiple: {
                                    single: {
                                        value: 0,
                                        enabled: false,
                                    },
                                    double: {
                                        value: 0,
                                        enabled: false,
                                    },
                                    triple: {
                                        value: 0,
                                        enabled: false,
                                    },
                                },
                            },
                        },
                        casino: {
                            baccarat: {
                                enabled: false,
                                type: "all",
                                parallel_any_amount_type: "win",
                                parallel_any_amount: false,
                                parallel_same_amount: false,
                                parallel_higher_amount: false,
                            },
                            table: {
                                value: 0,
                                enabled: false,
                            },
                            slots: {
                                value: 0,
                                enabled: false,
                            },
                            losing: {
                                value: 0,
                                enabled: false,
                            },
                        },
                        safety: {
                            table: {
                                value: 0,
                                enabled: false,
                            },
                            slots: {
                                value: 0,
                                enabled: false,
                            },
                        },
                    },
                    payments: {
                        total_deposit: 0,
                        website_commission: 0,
                        bank_commission: 0,
                        provider_commission: 0,
                        total_balance: 0,
                    },
                    table: {
                        minimum_amount: 0,
                        maximum_amount: 0,
                    },
                    slots: {
                        minimum_amount: 0,
                        maximum_amount: 0,
                    },
                },
            },
            pl_asset: {
                loading: false,
                amount: null,
                note: null,
            },
            pl_origin: {},
            referrer_ids: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
            agents_options: [],
            level_options: [
                { name: this.$t('level 1'), value: 1 },
                { name: this.$t('level 2'), value: 2 },
                { name: this.$t('level 3'), value: 3 },
                { name: this.$t('level 4'), value: 4 },
                { name: this.$t('level 5'), value: 5 },
                { name: this.$t('level 6'), value: 6 },
                { name: this.$t('level 7'), value: 7 },
                { name: this.$t('level 8'), value: 8 },
                { name: this.$t('level 9'), value: 9 },
                { name: this.$t('level 10'), value: 10 },
            ],
            user_id: null,
            referrer_code: null,
            referrer_id: null,
            referrer_username: null,
            referrer_role: null,
            referrer_type: null,
            check_referrer_id: null,
            loading: false,
            show_password: false,
            show_pin: false,
        };
    },
    validations() {
        return {
            pl: {
                agent_id: {
                    // requiredIf: helpers.withMessage(
                    //     'required',
                    //     requiredIf(function () {
                    //         return this._id == null;
                    //     })
                    // ),
                    validReferrer: helpers.withMessage(
                        'invalid referrer',
                        function (value) {
                            return !this.viewMode && this.referrer_code
                                ? this.pl.agent_id != null
                                : true;
                        }
                    ),
                },
                username: {
                    required: helpers.withMessage('required', required),
                    // alphaNumericOnly: helpers.withMessage(
                    //     'must consist of alphanumeric characters and numbers',
                    //     alphaNumericOnly
                    // ),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 20 characters',
                        maxLength(20)
                    ),
                    available: helpers.withMessage(
                        'username already taken',
                        function (value) {
                            return !this.viewMode ? this.user_id == null : true;
                        }
                    ),
                },
                password: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this._id == null;
                        })
                    ),
                    // validPassword: helpers.withMessage(
                    //     'must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character',
                    //     validPassword
                    // ),
                    // alphaNumericOnly: helpers.withMessage(
                    //     'must consist of alphanumeric characters and numbers',
                    //     alphaNumericOnly
                    // ),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                confirm_password: {
                    sameAs: helpers.withMessage(
                        `password doesn't match`,
                        sameAs(this.pl.password)
                    ),
                },
                pin: {
                    requiredIf: helpers.withMessage(
                        'required',
                        requiredIf(function () {
                            return this._id == null;
                        })
                    ),
                    minLength: helpers.withMessage(
                        'must consist of 4 characters',
                        minLength(4)
                    ),
                },
                name: {
                    // required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                email: {
                    email,
                    maxLength: helpers.withMessage(
                        'maximum length is 50 characters',
                        maxLength(50)
                    ),
                },
                mobile_number: {
                    minLength: helpers.withMessage(
                        'should be atleast 10 characters',
                        minLength(10)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 15 characters',
                        maxLength(15)
                    ),
                },
                messenger: {
                    telegram: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    line: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    whatsapp: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    wechat: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    facebook: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    skype: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    viber: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    qq: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                    kakao: {
                        maxLength: helpers.withMessage(
                            'maximum length is 32 characters',
                            maxLength(32)
                        ),
                    },
                },
                // customer_bank: {
                //     physical: {
                //         bank_name: { maxLength: helpers.withMessage('maximum length is 32 characters', maxLength(32)) },
                //         account_holder: { maxLength: helpers.withMessage('maximum length is 32 characters', maxLength(32)) },
                //         account_number: { maxLength: helpers.withMessage('maximum length is 32 characters', maxLength(32)) },
                //     },
                //     virtual: {
                //         bank_name: { maxLength: helpers.withMessage('maximum length is 32 characters', maxLength(32)) },
                //         account_holder: { maxLength: helpers.withMessage('maximum length is 32 characters', maxLength(32)) },
                //         account_number: { maxLength: helpers.withMessage('maximum length is 32 characters', maxLength(32)) },
                //     },
                // },
                customer_bank: {
                    physical: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                    virtual: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                },
                handler_bank: {
                    physical: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                    virtual: {
                        bank_name: {},
                        account_number: {},
                        account_holder: {},
                    },
                },
                agent: {
                    // level: { required: helpers.withMessage('required', required), },
                    referral_code: {
                        minLength: helpers.withMessage(
                            'should be atleast 2 characters',
                            minLength(2)
                        ),
                        available: helpers.withMessage(
                            'code already taken',
                            function (value) {
                                return !this.viewMode
                                    ? this.check_referrer_id == null
                                    : true;
                            }
                        ),
                        requiredIf: helpers.withMessage(
                            'required',
                            requiredIf(function (value) {
                                return (
                                    !this.viewMode &&
                                    (value == '' || value == null)
                                );
                            })
                        ),
                    },

                    commissions: {
                        losing: {
                            value: {
                                requiredIf: helpers.withMessage(
                                    'required',
                                    requiredIf(function (value) {
                                        if (
                                            value == 0 &&
                                            this.pl.agent.commissions.losing
                                                .enabled
                                        ) {
                                            return required(value);
                                        }
                                        return true;
                                    })
                                ),
                                maxValueValue: maxValue(100),
                            },
                        },
                        minigames: {
                            powerball: {
                                single: {
                                    value: {
                                        requiredIf: helpers.withMessage(
                                            'required',
                                            requiredIf(function (value) {
                                                if (
                                                    value == 0 &&
                                                    this.pl.agent.commissions
                                                        .minigames.powerball
                                                        .single.enabled
                                                ) {
                                                    return required(value);
                                                }
                                                return true;
                                            })
                                        ),
                                        maxValueValue: maxValue(100),
                                    },
                                },
                                multiple: {
                                    single: {
                                        value: {
                                            requiredIf: helpers.withMessage(
                                                'required',
                                                requiredIf(function (value) {
                                                    if (
                                                        value == 0 &&
                                                        this.pl.agent
                                                            .commissions
                                                            .minigames.powerball
                                                            .multiple.single
                                                            .enabled
                                                    ) {
                                                        return required(value);
                                                    }
                                                    return true;
                                                })
                                            ),
                                            maxValueValue: maxValue(100),
                                        },
                                    },
                                    double: {
                                        value: {
                                            requiredIf: helpers.withMessage(
                                                'required',
                                                requiredIf(function (value) {
                                                    if (
                                                        value == 0 &&
                                                        this.pl.agent
                                                            .commissions
                                                            .minigames.powerball
                                                            .multiple.double
                                                            .enabled
                                                    ) {
                                                        return required(value);
                                                    }
                                                    return true;
                                                })
                                            ),
                                            maxValueValue: maxValue(100),
                                        },
                                    },
                                    triple: {
                                        value: {
                                            requiredIf: helpers.withMessage(
                                                'required',
                                                requiredIf(function (value) {
                                                    if (
                                                        value == 0 &&
                                                        this.pl.agent
                                                            .commissions
                                                            .minigames.powerball
                                                            .multiple.triple
                                                            .enabled
                                                    ) {
                                                        return required(value);
                                                    }
                                                    return true;
                                                })
                                            ),
                                            maxValueValue: maxValue(100),
                                        },
                                    },
                                },
                            },
                        },
                        casino: {
                            table: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.agent.commissions.casino
                                                    .table.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            slots: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.agent.commissions.casino
                                                    .slots.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            losing: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.agent.commissions.casino
                                                    .losing.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                        },
                        safety: {
                            table: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.agent.commissions.safety
                                                    .table.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                            slots: {
                                value: {
                                    requiredIf: helpers.withMessage(
                                        'required',
                                        requiredIf(function (value) {
                                            if (
                                                value == 0 &&
                                                this.pl.agent.commissions.safety
                                                    .slots.enabled
                                            ) {
                                                return required(value);
                                            }
                                            return true;
                                        })
                                    ),
                                    maxValueValue: maxValue(100),
                                },
                            },
                        },
                    },

                    payments: {
                        total_deposit: {},
                        website_commission: {},
                        bank_commission: {},
                        provider_commission: {},
                    },
                    table: {
                        minimum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                        maximum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                    },
                    slots: {
                        minimum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                        maximum_amount: {
                            maxValueValue: helpers.withMessage(
                                'must not be greater than 100,000,000',
                                maxValue(100000000)
                            ),
                        },
                    },
                },
            },
            pl_asset: {
                amount: {
                    required: helpers.withMessage('required', required),
                    minValueValue: helpers.withMessage('required', minValue(1)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
                note: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    watch: {
        referrer_ids: {
            async handler(agents) {
                var id = null;
                agents.forEach((agent, i) => {
                    if (agent != null) {
                        id = agent._id;
                    }
                });
                this.pl.agent_id = id;
            },
            deep: true,
        },
        'pl.handler_bank.physical.default'(isDefault) {
            if (isDefault) {
                // this.pl.handler_bank.physical.enabled = true
                this.pl.handler_bank.virtual.default = false;
            }
        },
        'pl.handler_bank.virtual.default'(isDefault) {
            if (isDefault) {
                // this.pl.handler_bank.virtual.enabled = true
                this.pl.handler_bank.physical.default = false;
            }
        },
        'pl.agent.commissions.casino.baccarat.type'(type) {
            switch(type) {
                case 'all':
                        if(this.pl_origin.agent.commissions.casino.baccarat.type == "all") {
                            this.pl.agent.commissions.casino.baccarat.parallel_any_amount = this.pl_origin.agent.commissions.casino.baccarat.parallel_any_amount;
                            this.pl.agent.commissions.casino.baccarat.parallel_same_amount = this.pl_origin.agent.commissions.casino.baccarat.parallel_same_amount;
                            this.pl.agent.commissions.casino.baccarat.parallel_higher_amount = this.pl_origin.agent.commissions.casino.baccarat.parallel_higher_amount;
                        }else {
                            this.pl.agent.commissions.casino.baccarat.parallel_any_amount = true;
                        }
                    break;
                case 'win':
                case 'lose':
                case 'none':
                        this.pl.agent.commissions.casino.baccarat.parallel_any_amount = false;
                        this.pl.agent.commissions.casino.baccarat.parallel_same_amount = false;
                        this.pl.agent.commissions.casino.baccarat.parallel_higher_amount = false;
                    break;
            }
        },
        'pl.agent.commissions.casino.baccarat.parallel_any_amount'(enabled) {
            if(enabled) {
                this.pl.agent.commissions.casino.baccarat.parallel_same_amount = false;
                this.pl.agent.commissions.casino.baccarat.parallel_higher_amount = false;
            } 
        },
        'pl.agent.commissions.casino.baccarat.parallel_same_amount'(enabled) {
            if(enabled) {
                this.pl.agent.commissions.casino.baccarat.parallel_any_amount = false;
                this.pl.agent.commissions.casino.baccarat.parallel_higher_amount = false;
            } 
        },
        'pl.agent.commissions.casino.baccarat.parallel_higher_amount'(enabled) {
            if(enabled) {
                this.pl.agent.commissions.casino.baccarat.parallel_any_amount = false;
                this.pl.agent.commissions.casino.baccarat.parallel_same_amount = false;
            } 
        },
        // referrer_code() {
        //     this.getReferrer();
        // },
        // 'pl.agent.referral_code'() {
        //     this.checkReferrerCode();
        // },
        // 'pl.username'() {
        //     this.getUsernames();
        // },
    },
    computed: {
        viewMode() {
            return this._id != null;
        },
        forms() {
            var m_fd = this.formDataToObject(this.objectToFormData(this.pl));
            var o_fd = this.formDataToObject(
                this.objectToFormData(this.pl_origin)
            );
            var f_fd = new FormData();
            var rows = '';
            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];
                    if (!this.viewMode) {
                        // CREATE MODE
                        if (
                            !(m_fd[key] == null || m_fd[key] == '') &&
                            !f_fd.has(key)
                        ) {
                            f_fd.append(key, m_value);
                        }
                    } else {
                        // EDIT MODE
                        if (
                            m_fd[key] != o_fd[key] &&
                            ![
                                'agent[commissions][enabled]',
                                'agent[referral_code]',
                            ].includes(key)
                        ) {
                            if (
                                [
                                    'handler_bank[physical][bank_name]',
                                    'handler_bank[physical][account_holder]',
                                    'handler_bank[physical][account_number]',
                                ].includes(key)
                            ) {
                                if (
                                    !f_fd.has(
                                        'handler_bank[physical][bank_name]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[physical][bank_name]',
                                        m_fd[
                                            'handler_bank[physical][bank_name]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[physical][account_holder]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[physical][account_holder]',
                                        m_fd[
                                            'handler_bank[physical][account_holder]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[physical][account_number]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[physical][account_number]',
                                        m_fd[
                                            'handler_bank[physical][account_number]'
                                        ] || ''
                                    );
                                }
                            } else if (
                                [
                                    'handler_bank[virtual][bank_name]',
                                    'handler_bank[virtual][account_holder]',
                                    'handler_bank[virtual][account_number]',
                                ].includes(key)
                            ) {
                                if (
                                    !f_fd.has(
                                        'handler_bank[virtual][bank_name]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[virtual][bank_name]',
                                        m_fd[
                                            'handler_bank[virtual][bank_name]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[virtual][account_holder]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[virtual][account_holder]',
                                        m_fd[
                                            'handler_bank[virtual][account_holder]'
                                        ] || ''
                                    );
                                }
                                if (
                                    !f_fd.has(
                                        'handler_bank[virtual][account_number]'
                                    )
                                ) {
                                    f_fd.append(
                                        'handler_bank[virtual][account_number]',
                                        m_fd[
                                            'handler_bank[virtual][account_number]'
                                        ] || ''
                                    );
                                }
                            } else {
                                f_fd.append(key, m_value);
                                if (
                                    key ==
                                        'agent[commissions][casino][table][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][casino][table][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'agent[commissions][casino][slots][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][casino][slots][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'agent[commissions][casino][losing][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][casino][losing][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'agent[commissions][safety][table][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][safety][table][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'agent[commissions][safety][slots][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][safety][slots][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'agent[commissions][minigames][powerball][single][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][minigames][powerball][single][value]',
                                        0
                                    );
                                }
                                if (
                                    key ==
                                        'agent[commissions][minigames][powerball][multiple][single][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][minigames][powerball][multiple][single][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'agent[commissions][minigames][powerball][multiple][double][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][minigames][powerball][multiple][double][value]',
                                        0
                                    );
                                }

                                if (
                                    key ==
                                        'agent[commissions][minigames][powerball][multiple][triple][enabled]' &&
                                    m_value == '0'
                                ) {
                                    f_fd.append(
                                        'agent[commissions][minigames][powerball][multiple][triple][value]',
                                        0
                                    );
                                }
                            }

                            if (!['confirm_password'].includes(key)) {
                                rows += `<tr>
                                    <td>${this.$t(this.arrayToArrows(key))}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${this.$t(
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key] || ''
                                        )}
                                    </td>
                                    <td class="${this.$t(
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    )}">
                                        ${this.$t(
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key] || ''
                                        )}
                                    </td>
                                </tr>`;
                            }
                        }
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    methods: {
        ...mapActions('referrer', {
            referrerView: 'view',
        }),
        ...mapActions('agent', {
            agentGetList: 'getList',
            agentGetFilterList: 'getFilterList',
            agentCreate: 'create',
            agentUpdate: 'update',
            agentUpdateAssets: 'updateAssets',
            agentView: 'view',
            agentViewPassword: 'viewPassword',
        }),
        async submitForm(e) {
            if (!this.isAuthorized()) return;
            e.preventDefault();
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var swal_html = `
                        <table class="table table-sm table-bordered fs-6">
                            <thead>
                                <tr>
                                    <th>${this.$t('field')}</th>
                                    <th>${this.$t('before')}</th>
                                    <th>${this.$t('after')}</th>
                                </tr>
                            </thead>
                            <tbody>${this.forms.changes}</tbody>
                        </table>
                    `;
                if (this.viewMode && !this.forms.changes) {
                    Swal.fire({
                        icon: 'info',
                        title: this.$t('no changes made'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }

                const confirmed = await Swal.fire({
                    title: this.viewMode
                        ? this.$t('do you want to modify the information?')
                        : `${this.$t('create new agent?')}`,
                    // html: this.forms.changes ? swal_html : '',
                    icon: this.viewMode ? 'info' : 'warning',
                    // width: this.viewMode ? 800 : null,
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    this.loading = true;
                    const res = !this.viewMode
                        ? await this.agentCreate(this.forms.form_data)
                        : await this.agentUpdate({
                              form_data: this.forms.x_www_form_urlencoded,
                              id: this._id,
                          });
                    this.loading = false;

                    if (res) {
                        if (this.viewMode) {
                            if (res.success) {
                                this.getDetails();
                            }
                            this.pl.password = null;
                            this.pl.confirm_password = null;
                            this.pl.pin = null;
                        } else {
                            if (res.success) {
                                await this.reset();
                                this.reset();
                            }
                        }

                        var title = {
                            create: res.success
                                ? this.$t('new account has been created.')
                                : this.$t('creating failed'),
                            update: res.success
                                ? this.$t('agent account has been updated')
                                : this.$t('updating failed'),
                        };

                        var field_rows = ``;
                        var errors =
                            !res.success && res.data.errors
                                ? this.convertObjectToArray(res.data.errors)
                                : [];

                        errors.forEach((e) => {
                            var field_value_rows = ``;
                            e.errors.forEach((err) => {
                                field_value_rows += `<div class="text-danger">${err.toLowerCase()}</div>`;
                            });
                            field_rows += `
                            <tr>
                                <td>${this.$t(e.field)}</td>
                                <td>${field_value_rows}</td>
                            </tr>
                            `;
                        });

                        var errors_html = `
                            <table class="table table-sm table-bordered fs-6">
                                <thead>
                                    <tr>
                                        <th>${this.$t('field')}</th>
                                        <th>${this.$t('validation')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${field_rows}
                                </tbody>
                            </table>
                        `;

                        if (!res.success && errors.length <= 0) {
                            errors_html = res.data.message;
                        }

                        if (res.success) {
                            window.opener.postMessage('reload-agents');
                        }

                        Swal.fire({
                            title: this.viewMode
                                ? this.$t(title.update)
                                : this.$t(title.create),
                            html: res.success ? '' : errors_html,
                            icon: res.data.status,
                            // width: res.success && !this.viewMode ? null : 800,
                            timer: res.success ? 2000 : null,
                            confirmButtonText: this.$t('ok'),
                        });
                    }
                }
            }
        },
        async getDetails() {
            if (!this._id) {
                this.pl_origin = JSON.parse(JSON.stringify(this.pl));
                return;
            }
            this.loading = true;
            const data = await this.agentView(this._id);
            this.loading = false;

            this.pl.agent_id = data.agent_id;
            this.pl.type = data.type;
            this.pl.username = data.username;
            this.pl.status = data.status;
            this.pl.name = data.name || null;
            this.pl.email = data.email || null;
            this.pl.mobile_number = data.mobile_number || null;
            this.pl.messenger.telegram = data.messenger.telegram || null;
            this.pl.messenger.line = data.messenger.line || null;
            this.pl.messenger.whatsapp = data.messenger.whatsapp || null;
            this.pl.messenger.wechat = data.messenger.wechat || null;
            this.pl.messenger.facebook = data.messenger.facebook || null;
            this.pl.messenger.skype = data.messenger.skype || null;
            this.pl.messenger.viber = data.messenger.viber || null;
            this.pl.messenger.qq = data.messenger.qq || null;
            this.pl.messenger.kakao = data.messenger.kakao || null;
            this.pl.customer_bank.virtual.bank_name =
                data.customer_bank.virtual.bank_name || null;
            this.pl.customer_bank.virtual.account_holder =
                data.customer_bank.virtual.account_holder || null;
            this.pl.customer_bank.virtual.account_number =
                data.customer_bank.virtual.account_number || null;
            this.pl.customer_bank.physical.bank_name =
                data.customer_bank.physical.bank_name || null;
            this.pl.customer_bank.physical.account_holder =
                data.customer_bank.physical.account_holder || null;
            this.pl.customer_bank.physical.account_number =
                data.customer_bank.physical.account_number || null;

            this.pl.handler_bank.physical.enabled =
                data.handler_bank.physical.enabled || false;
            this.pl.handler_bank.physical.default =
                data.handler_bank.physical.default || false;
            this.pl.handler_bank.physical.bank_name =
                data.handler_bank.physical.bank_name || null;
            this.pl.handler_bank.physical.account_holder =
                data.handler_bank.physical.account_holder || null;
            this.pl.handler_bank.physical.account_number =
                data.handler_bank.physical.account_number || null;
            this.pl.handler_bank.virtual.enabled =
                data.handler_bank.virtual.enabled || false;
            this.pl.handler_bank.virtual.default =
                data.handler_bank.virtual.default || false;
            this.pl.handler_bank.virtual.bank_name =
                data.handler_bank.virtual.bank_name || null;
            this.pl.handler_bank.virtual.account_holder =
                data.handler_bank.virtual.account_holder || null;
            this.pl.handler_bank.virtual.account_number =
                data.handler_bank.virtual.account_number || null;

            this.pl.agent.referral_code = data.agent.referral_code;
            this.pl.agent.level = data.agent.level;
            this.pl.agent.commissions.type = data.agent.commissions.type;
            this.pl.agent.commissions.enabled =
                data.agent.commissions.enabled || false;
            this.pl.agent.commissions.losing.value =
                data.agent.commissions.losing.value;
            this.pl.agent.commissions.losing.enabled =
                data.agent.commissions.losing.enabled;
            this.pl.agent.commissions.minigames.powerball.type =
                data.agent.commissions.minigames.powerball.type;
            this.pl.agent.commissions.minigames.powerball.enabled =
                data.agent.commissions.minigames.powerball.enabled;
            this.pl.agent.commissions.minigames.powerball.single.value =
                data.agent.commissions.minigames.powerball.single.value;
            this.pl.agent.commissions.minigames.powerball.single.enabled =
                data.agent.commissions.minigames.powerball.single.enabled;
            this.pl.agent.commissions.minigames.powerball.multiple.single.value =
                data.agent.commissions.minigames.powerball.multiple.single.value;
            this.pl.agent.commissions.minigames.powerball.multiple.single.enabled =
                data.agent.commissions.minigames.powerball.multiple.single.enabled;
            this.pl.agent.commissions.minigames.powerball.multiple.double.value =
                data.agent.commissions.minigames.powerball.multiple.double.value;
            this.pl.agent.commissions.minigames.powerball.multiple.double.enabled =
                data.agent.commissions.minigames.powerball.multiple.double.enabled;
            this.pl.agent.commissions.minigames.powerball.multiple.triple.value =
                data.agent.commissions.minigames.powerball.multiple.triple.value;
            this.pl.agent.commissions.minigames.powerball.multiple.triple.enabled =
                data.agent.commissions.minigames.powerball.multiple.triple.enabled;

            this.pl.agent.commissions.casino.baccarat.enabled =
                data.agent.commissions.casino.baccarat.enabled;

            this.pl.agent.commissions.casino.baccarat.type =
                data.agent.commissions.casino.baccarat.type;

            this.pl.agent.commissions.casino.baccarat.parallel_any_amount_type =
                data.agent.commissions.casino.baccarat.parallel_any_amount_type;

            this.pl.agent.commissions.casino.baccarat.parallel_any_amount =
                data.agent.commissions.casino.baccarat.parallel_any_amount;

            this.pl.agent.commissions.casino.baccarat.parallel_same_amount =
                data.agent.commissions.casino.baccarat.parallel_same_amount;

            this.pl.agent.commissions.casino.baccarat.parallel_higher_amount =
                data.agent.commissions.casino.baccarat.parallel_higher_amount;

            this.pl.agent.commissions.casino.table.value =
                data.agent.commissions.casino.table.value;
            this.pl.agent.commissions.casino.table.enabled =
                data.agent.commissions.casino.table.enabled;
            this.pl.agent.commissions.casino.slots.value =
                data.agent.commissions.casino.slots.value;
            this.pl.agent.commissions.casino.slots.enabled =
                data.agent.commissions.casino.slots.enabled;
            this.pl.agent.commissions.casino.losing.value =
                data.agent.commissions.casino.losing.value;
            this.pl.agent.commissions.casino.losing.enabled =
                data.agent.commissions.casino.losing.enabled;
            this.pl.agent.commissions.safety.table.value =
                data.agent.commissions.safety.table.value;
            this.pl.agent.commissions.safety.table.enabled =
                data.agent.commissions.safety.table.enabled;
            this.pl.agent.commissions.safety.slots.value =
                data.agent.commissions.safety.slots.value;
            this.pl.agent.commissions.safety.slots.enabled =
                data.agent.commissions.safety.slots.enabled;

            this.pl.agent.table.minimum_amount =
                data.agent.table.minimum_amount;
            this.pl.agent.table.maximum_amount =
                data.agent.table.maximum_amount;
            this.pl.agent.slots.minimum_amount =
                data.agent.slots.minimum_amount;
            this.pl.agent.slots.maximum_amount =
                data.agent.slots.maximum_amount;

            this.pl.agent.abilities.create_agent =
                data.agent.abilities.create_agent;
            this.pl.agent.abilities.add_agent_balance =
                data.agent.abilities.add_agent_balance;
            this.pl.agent.abilities.subtract_agent_balance =
                data.agent.abilities.subtract_agent_balance;
            this.pl.agent.abilities.create_player =
                data.agent.abilities.create_player;
            this.pl.agent.abilities.add_player_balance =
                data.agent.abilities.add_player_balance;
            this.pl.agent.abilities.subtract_player_balance =
                data.agent.abilities.subtract_player_balance;

            this.pl.agent.abilities.update_player_password =
                data.agent.abilities.update_player_password;
            this.pl.agent.abilities.view_player_password =
                data.agent.abilities.view_player_password;

            if (data.agent_referrer) {
                this.pl.agent_referrer.name = data.agent_referrer.name || null;
                this.pl.agent_referrer.username =
                    data.agent_referrer.username || null;
                // this.pl.agent_referrer.agent.level =
                //     data.agent_referrer.agent.level || null;
            }
            this.pl.agent_upline = data.agent_upline;

            if (data.agent.payments) {
                this.pl.agent.payments.total_deposit =
                    data.agent.payments.total_deposit || 0;
                this.pl.agent.payments.website_commission =
                    data.agent.payments.website_commission || 0;
                this.pl.agent.payments.bank_commission =
                    data.agent.payments.bank_commission || 0;
                this.pl.agent.payments.provider_commission =
                    data.agent.payments.provider_commission || 0;

                this.pl.agent.payments.total_balance =
                    data.agent.payments.total_balance || 0;
            }

            this.pl.agent.note = data.agent.note;
            this.pl_origin = JSON.parse(JSON.stringify(data));
        },
        async submitAsset(asset, action) {
            this.asset_submitted = true;
            this.v$[`pl_asset`].$touch();
            if (this.v$[`pl_asset`].$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure')}?`,
                    html: `<table class="table table-bordered table-sm ">
                        <tbody>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'action'
                                )}</th>
                                <td>${this.$t(action)} ${this.numberString(
                        this[`pl_asset`].amount
                    )} ${this.$t(asset)}</td>
                            </tr>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'username'
                                )}</th>
                                <td>${this.pl.username}</td>
                            </tr>
                        </tbody>
                    </table>`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('amount', this[`pl_asset`].amount);
                    fd.append('note', this[`pl_asset`].note);

                    this[`pl_asset`].loading = true;
                    const res = await this.agentUpdateAssets({
                        id: this._id,
                        asset: 'balance',
                        action: action,
                        form_data: fd,
                    });
                    this[`pl_asset`].loading = false;
                    if (res) {
                        Swal.fire({
                            html: this.$t(res.data.message.toLowerCase()),
                            icon: res.success ? 'success' : 'error',
                            timer: res.success ? 2000 : null,
                        });
                        this.getDetails();
                        this[`pl_asset`].amount = 0;
                        this[`pl_asset`].note = null;
                        this.asset_submitted = false;
                    }
                }
            }
        },
        async getPassword() {
            if (!this.isAuthorized()) return;
            if (!this._id) {
                return;
            }
            const data = await this.agentViewPassword(this._id);
            this.pl_origin.password = data.raw_password;
            this.pl.password = data.raw_password;
        },
        async getPin() {
            if (!this.isAuthorized()) return;
            if (!this._id) {
                return;
            }
            const data = await this.agentViewPassword(this._id);
            this.pl_origin.pin = data.raw_pin;
            this.pl.pin = data.raw_pin || null;
        },
        async getReferrer() {
            this.v$.pl.agent_id.$touch();
            this.pl.agent_id = null;
            this.referrer_role = null;
            this.referrer_type = null;
            this.pl.agent_referrer.username = null;
            this.pl.agent_referrer.role = null;
            // if (!this.referrer_code) return;
            if (this.referrer_code == null || this.referrer_code == '') return;

            const referrer = await this.referrerView(this.referrer_code);
            if (referrer) {
                this.referrer_role = referrer.role;
                this.referrer_type = referrer.type;
                this.pl.type = referrer.type;

                this.pl.agent_id = referrer._id;
                this.pl.agent_referrer.role = referrer.role;
                this.pl.agent_referrer.username = referrer.username;
            }
        },
        async checkReferrerCode() {
            this.v$.pl.agent.referral_code.$touch();
            this.check_referrer_id = null;
            // if (!this.pl.agent.referral_code) return;
            if (
                this.pl.agent.referral_code == null ||
                this.pl.agent.referral_code == ''
            )
                return;
            const referrer = await this.referrerView(
                this.pl.agent.referral_code
            );
            if (referrer) {
                this.check_referrer_id = referrer._id;
            }
        },
        async getUsernames() {
            if (this.pl.username == null || this.pl.username == '') return;
            var pl = {
                username: this.pl.username || null,
            };
            const users = await this.agentGetList(pl);
            this.user_id = null;
            if (users.data.length > 0) {
                this.user_id = users.data[0]._id;
            }
            this.v$.pl.username.$touch();
        },
        async reset() {
            // this.handleClear(0);
            this.submitted = false;
            this.pl.agent_id = null;
            this.pl.type = 'offline';
            this.pl.username = null;
            this.referrer_code = null;
            this.pl.status = 'active';
            this.pl.password = null;
            this.pl.confirm_password = null;
            this.pl.pin = null;
            this.pl.name = null;
            this.pl.nickname = null;
            this.pl.email = null;
            this.pl.mobile_number = null;
            this.pl.messenger.telegram = null;
            this.pl.messenger.line = null;
            this.pl.messenger.whatsapp = null;
            this.pl.messenger.wechat = null;
            this.pl.messenger.facebook = null;
            this.pl.messenger.skype = null;
            this.pl.messenger.viber = null;
            this.pl.messenger.qq = null;
            this.pl.messenger.kakao = null;
            this.pl.customer_bank.virtual.bank_name = null;
            this.pl.customer_bank.virtual.account_holder = null;
            this.pl.customer_bank.virtual.account_number = null;
            this.pl.customer_bank.physical.bank_name = null;
            this.pl.customer_bank.physical.account_holder = null;
            this.pl.customer_bank.physical.account_number = null;
            this.pl.handler_bank.virtual.bank_name = null;
            this.pl.handler_bank.virtual.account_holder = null;
            this.pl.handler_bank.virtual.account_number = null;
            this.pl.handler_bank.physical.bank_name = null;
            this.pl.handler_bank.physical.account_holder = null;
            this.pl.handler_bank.physical.account_number = null;
            this.pl.agent.referral_code = null;
            this.pl.agent.level = null;
            this.pl.agent.commissions.type = 'bet';
            this.pl.agent.commissions.enabled = true;
            this.pl.agent.commissions.losing.value = 0;
            this.pl.agent.commissions.losing.enabled = false;
            this.pl.agent.commissions.minigames.powerball.type = 'single';
            this.pl.agent.commissions.minigames.powerball.enabled = false;
            this.pl.agent.commissions.minigames.powerball.single.value = 0;
            this.pl.agent.commissions.minigames.powerball.single.enabled = false;
            this.pl.agent.commissions.minigames.powerball.multiple.single.value = 0;
            this.pl.agent.commissions.minigames.powerball.multiple.single.enabled = false;
            this.pl.agent.commissions.minigames.powerball.multiple.double.value = 0;
            this.pl.agent.commissions.minigames.powerball.multiple.double.enabled = false;
            this.pl.agent.commissions.minigames.powerball.multiple.triple.value = 0;
            this.pl.agent.commissions.minigames.powerball.multiple.triple.enabled = false;
            this.pl.agent.commissions.casino.baccarat.enabled = false;

            this.pl.agent.commissions.casino.baccarat.type = 'all';
            this.pl.agent.commissions.casino.baccarat.parallel_any_amount_type = 'win';
            
            this.pl.agent.commissions.casino.baccarat.parallel_any_amount = false;

            this.pl.agent.commissions.casino.baccarat.parallel_same_amount = false;

            this.pl.agent.commissions.casino.baccarat.parallel_higher_amount = false;
            this.pl.agent.commissions.casino.table.value = 0;
            this.pl.agent.commissions.casino.table.enabled = false;
            this.pl.agent.commissions.casino.slots.value = 0;
            this.pl.agent.commissions.casino.slots.enabled = false;
            this.pl.agent.commissions.casino.losing.value = 0;
            this.pl.agent.commissions.casino.losing.enabled = false;
            this.pl.agent.commissions.safety.table.value = 0;
            this.pl.agent.commissions.safety.table.enabled = false;
            this.pl.agent.commissions.safety.slots.value = 0;
            this.pl.agent.commissions.safety.slots.enabled = false;
            this.pl.agent.abilities.create_agent = false;
            this.pl.agent.abilities.add_agent_balance = false;
            this.pl.agent.abilities.subtract_agent_balance = false;
            this.pl.agent.abilities.create_player = false;
            this.pl.agent.abilities.add_player_balance = false;
            this.pl.agent.abilities.update_player_password = false;
            this.pl.agent.abilities.view_player_password = false;
            this.pl.agent.note = null;
            this.v$.$reset();
            this.v$.pl.agent_id.$reset();
            this.v$.pl.agent.referral_code.$reset();
            this.v$.pl.username.$reset();
            this.v$.pl_asset.$reset();
        } /*  */,
        // handleFilter(value) {
        //     if (!value) return;
        //     var startIndex = value.option_index + 1;
        //     var loop = this.agents_options.length;
        //     if (startIndex < loop) {
        //         for (var i = startIndex; i < loop; i++) {
        //             this.agents_options.splice(startIndex, 1);
        //             this.referrer_ids[i] = null;
        //         }
        //     }
        //     this.initFilter(value._id);
        // },
        // handleClear(index) {
        //     var loop = this.agents_options.length;
        //     var startIndex = index + 1;
        //     for (var i = startIndex; i < loop; i++) {
        //         this.agents_options.splice(startIndex, 1);
        //         this.referrer_ids[i] = null;
        //     }
        //     if (index == 0) this.referrer_ids[0] = null;
        // },
        // async initFilter(check_referrer_id) {
        //     const data = await this.agentGetFilterList({
        //         referrer: check_referrer_id || null,
        //     });
        //     var agents = [];
        //     data.forEach((agent) => {
        //         agent.option_index = this.agents_options.length;
        //         agents.push({
        //             label: agent.username,
        //             value: agent,
        //         });
        //     });
        //     // this.referrer_ids.push()
        //     if (agents.length) {
        //         this.agents_options.push(agents);
        //     }
        // },
    },
    mounted() {
        // this.initFilter();
        this.getDetails();
    },
};
</script>
